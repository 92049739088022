import React, { useState, useEffect } from "react";
import { Auth } from "@supabase/auth-ui-react";
import { ThemeSupa } from "@supabase/auth-ui-shared";

const LoginPage = ({ supabase, setSession }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const getURL = () => {
    let url =
      process.env.REACT_APP_PUBLIC_SITE_URL ?? // Set this to your site URL in production env.
      process.env.NEXT_PUBLIC_VERCEL_URL ?? // Automatically set by Vercel.
      "http://localhost:3000/";
    // Make sure to include `https://` when not localhost.
    url = url.startsWith("http") ? url : `https://${url}`;
    // Make sure to include a trailing `/`.
    url = url.endsWith("/") ? url : `${url}/`;
    return url;
  };

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
    });

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });

    return () => subscription.unsubscribe();
  }, [supabase, setSession]);

  return (
    <div className="flex items-center justify-center min-h-screen bg-zinc-900 p-4">
      <div className="w-full max-w-md p-8 space-y-8 bg-zinc-800 rounded-lg shadow-xl">
        <div className="text-center mb-8">
          <h2 className="text-3xl font-bold text-zinc-100">Welcome</h2>
          <p className="mt-2 text-sm text-zinc-400">Sign in to your account</p>
        </div>
        <Auth
          supabaseClient={supabase}
          queryParams={{
            redirectTo: getURL(),
          }}
          appearance={{
            theme: ThemeSupa,
            variables: {
              default: {
                colors: {
                  brand: "rgb(79, 70, 229)", // accent-indigo
                  brandAccent: "rgb(67, 56, 202)", // darker shade of accent-indigo
                  inputBackground: "rgb(39, 39, 42)", // zinc-800
                  inputText: "rgb(244, 244, 245)", // zinc-100
                },
              },
            },
            className: {
              container: "auth-container",
              button: "auth-button",
              input: "auth-input",
              label: "auth-label",
              anchor: "auth-anchor",
            },
          }}
          localization={{
            variables: {
              sign_in: {
                email_label: "Email address",
                password_label: "Password",
              },
            },
          }}
        />
      </div>
    </div>
  );
};

export default LoginPage;
