import React from "react";

const FlashcardList = ({
  title,
  flashcards,
  actionLabel,
  onAction,
  actionColor,
  onThumbsDown,
  showThumbsDown = false,
}) => (
  <div className="flex-1 h-full flex flex-col min-w-0 w-full">
    <h2 className="text-xl text-white font-bold mb-4 text-center">{title}</h2>
    <div className="flex-grow overflow-hidden bg-zinc-800 rounded-lg shadow-md">
      <div className="h-full overflow-y-auto px-3 py-2">
        <ul className="space-y-3">
          {flashcards.map((flashcard) => (
            <li
              key={flashcard.id}
              className="bg-zinc-700 rounded-md overflow-hidden transition-all duration-300 hover:shadow-lg hover:scale-[1.01]"
            >
              <div className="p-3 border-b border-zinc-600">
                <h3 className="text-gray-300 font-semibold mb-1 text-xs uppercase tracking-wide">
                  Question:
                </h3>
                <p className="text-white text-sm">{flashcard.question}</p>
              </div>
              <div className="p-3">
                <h3 className="text-gray-300 font-semibold mb-1 text-xs uppercase tracking-wide">
                  Answer:
                </h3>
                <p className="text-white text-sm">{flashcard.answer}</p>
              </div>
              <div className="px-3 py-2 bg-zinc-900 flex justify-end space-x-2">
                {showThumbsDown && (
                  <button
                    onClick={() => onThumbsDown(flashcard.id)}
                    className="text-red-500 hover:text-red-600 text-xs font-medium py-1 px-3 rounded-full transition-all duration-300 hover:shadow-md hover:scale-105"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path d="M18 9.5a1.5 1.5 0 11-3 0v-6a1.5 1.5 0 013 0v6zM14 9.667v-5.43a2 2 0 00-1.105-1.79l-.05-.025A4 4 0 0011.055 2H5.64a2 2 0 00-1.962 1.608l-1.2 6A2 2 0 004.44 12H8v4a2 2 0 002 2 1 1 0 001-1v-.667a4 4 0 01.8-2.4l1.4-1.866a4 4 0 00.8-2.4z" />
                    </svg>
                  </button>
                )}
                <button
                  onClick={() => onAction(flashcard.id)}
                  className={`${actionColor} text-white text-xs font-medium py-1 px-3 rounded-full transition-all duration-300 hover:shadow-md hover:scale-105`}
                >
                  {actionLabel}
                </button>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  </div>
);

export default FlashcardList;
