import React, { useState } from "react";
import FlashcardList from "./FlashcardList";
export const FlashcardManager = ({
  availableFlashcards,
  addedFlashcards,
  handleAdd,
  handleRemove,
  handleThumbsDown,
}) => {
  const [activeTab, setActiveTab] = useState("available");

  const TabButton = ({ label, isActive, onClick }) => (
    <button
      onClick={onClick}
      className={`flex-1 py-2 text-sm font-medium transition-colors duration-300 ${
        isActive
          ? "text-white border-b-2 border-blue-500"
          : "text-gray-400 hover:text-white"
      }`}
    >
      {label}
    </button>
  );

  return (
    <div className="flex flex-col lg:flex-row items-stretch justify-center h-full w-full space-y-4 lg:space-y-0 lg:space-x-6">
      {/* Desktop view */}
      <div className="hidden lg:flex lg:flex-row w-full space-x-6">
        <FlashcardList
          title="Available Flashcards"
          flashcards={availableFlashcards}
          actionLabel="Add to Deck"
          onAction={handleAdd}
          actionColor="bg-blue-500 hover:bg-blue-600"
          onThumbsDown={handleThumbsDown}
          showThumbsDown={true}
        />
        <FlashcardList
          title="Added Flashcards"
          flashcards={addedFlashcards}
          actionLabel="Remove from Deck"
          onAction={handleRemove}
          actionColor="bg-red-500 hover:bg-red-600"
        />
      </div>

      {/* Mobile view */}
      <div className="lg:hidden w-full flex flex-col h-full">
        <div className="flex mb-4 bg-zinc-800 rounded-lg overflow-hidden">
          <TabButton
            label="Available"
            isActive={activeTab === "available"}
            onClick={() => setActiveTab("available")}
          />
          <TabButton
            label="Added"
            isActive={activeTab === "added"}
            onClick={() => setActiveTab("added")}
          />
        </div>
        <div className="flex-1 overflow-hidden">
          {activeTab === "available" && (
            <FlashcardList
              title="Available Flashcards"
              flashcards={availableFlashcards}
              actionLabel="Add to Deck"
              onAction={handleAdd}
              actionColor="bg-blue-500 hover:bg-blue-600"
              onThumbsDown={handleThumbsDown}
              showThumbsDown={true}
            />
          )}
          {activeTab === "added" && (
            <FlashcardList
              title="Added Flashcards"
              flashcards={addedFlashcards}
              actionLabel="Remove from Deck"
              onAction={handleRemove}
              actionColor="bg-red-500 hover:bg-red-600"
            />
          )}
        </div>
      </div>
    </div>
  );
};
