import React from "react";

const Footer = () => {
  return (
    <footer className="bg-gray-800 text-white py-8">
      <div className="container mx-auto px-4 text-center">
        <p className="text-sm mb-4">
          © {new Date().getFullYear()} Quantiqa. All rights reserved. Built by{" "}
          <a
            href="https://quantiqa.de"
            className="text-blue-400 hover:underline"
          >
            Quantiqa
          </a>
          .
        </p>
        <div className="text-sm">
          <a href="/impressum" className="text-gray-400 hover:underline mx-2">
            Impressum
          </a>
          |
          <a
            href="/privacy-policy"
            className="text-gray-400 hover:underline mx-2"
          >
            Privacy Policy
          </a>
          |
          <a
            href="/terms-of-service"
            className="text-gray-400 hover:underline mx-2"
          >
            Terms of Service
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
