import React, { useEffect, useState } from "react";
import Toolbar from "../../components/Toolbar";
import CardGroup from "../../components/CardGroup";
import CreateDeckModal from "../../components/CreateDeckModal";
import { ErrorAlert, SavedAlert } from "../../components/Alert";
import { useDecks } from "../../services/DeckProvider";
import ExpandableChatSidebar from "../Chat/ChatPage";
import { MessageCircle } from "lucide-react";

const Dashboard = ({ session }) => {
  const [openCreateNewDeck, setOpenCreateNewDeck] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [favorites, setFavorites] = useState([]);
  const { decks, membership, username } = useDecks();
  const [isChatOpen, setIsChatOpen] = useState(false);

  useEffect(() => {
    setFavorites(decks.filter((deck) => deck.favorite));
  }, [decks]);

  return (
    <div className="bg-[#121212] text-zinc-100 min-h-screen relative">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        {showAlert && (
          <SavedAlert
            title="Deck was created"
            message="Deck was successfully created!"
            setShow={setShowAlert}
          />
        )}
        {showError && (
          <ErrorAlert
            title="Error"
            message="There was an error creating the deck"
            setShow={setShowError}
          />
        )}
        <div className="flex flex-col sm:flex-row w-full items-center justify-between mb-8">
          <h2 className="text-3xl font-bold text-zinc-100 mb-4 sm:mb-0">
            Welcome, {session ? username || session.user.email : "User"}
          </h2>
          <div className="flex items-center space-x-4">
            <Toolbar
              openCreateNewDeck={openCreateNewDeck}
              setOpenCreateNewDeck={setOpenCreateNewDeck}
            />
            {/* Uncomment if you want to add the chat feature
            <button
              onClick={() => setIsChatOpen(true)}
              className="bg-indigo-600 hover:bg-indigo-700 text-white rounded-full p-2 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-all duration-200"
            >
              <MessageCircle size={24} />
            </button>
            */}
          </div>
        </div>
        <CardGroup favorites={favorites} />
        {openCreateNewDeck && (
          <CreateDeckModal
            setIsOpen={setOpenCreateNewDeck}
            isOpen={openCreateNewDeck}
            setShowAlert={setShowAlert}
            setShowError={setShowError}
          />
        )}
        {/* Uncomment if you want to add the chat feature
        <ExpandableChatSidebar
          isOpen={isChatOpen}
          onClose={() => setIsChatOpen(false)}
          initialMessages={[
            {
              type: "system",
              content: "Welcome to the chat! How can I assist you today?",
            },
          ]}
        />
        */}
      </div>
    </div>
  );
};

export default Dashboard;
