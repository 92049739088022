import React, { useState, useEffect } from "react";
import { Search, Edit3, Sparkles, AlertCircle } from "lucide-react";
import CardGrid from "../../components/Decks/CardGrid";
import { NoDecksAvailable } from "../../components/NoCards";
import FlashcardGenerator from "../../components/GenerateCards/FlashCardGenerator";
import Toolbar from "../../components/Decks/Toolbar";

const CustomAlert = ({ message }) => (
  <div className="bg-zinc-800 border-l-4 border-red-500 text-zinc-100 p-4 rounded-md mb-4 flex items-center shadow-md">
    <AlertCircle className="h-5 w-5 mr-3 text-red-500 flex-shrink-0" />
    <span className="text-sm">{message}</span>
  </div>
);

const TabSystem = ({
  decks,
  cardSearchTerm,
  setCardSearchTerm,
  filteredCards,
  setSelectedCard,
  setIsCardDetailOpen,
  setShowCreateDeckModal,
  selectedDeck,
  setSelectedDeck,
  onSave,
  onFavorite,
  onDeleteDeck,
  setIsEditing,
}) => {
  const [activeTab, setActiveTab] = useState("edit");
  const [availableFlashcards, setAvailableFlashcards] = useState([]);
  const [addedFlashcards, setAddedFlashcards] = useState([]);
  const [showWarning, setShowWarning] = useState(false);

  const tabs = [
    { id: "edit", label: "Edit Deck", icon: Edit3 },
    { id: "generate", label: "Generate Cards", icon: Sparkles },
  ];

  useEffect(() => {
    setAvailableFlashcards([]);
    setAddedFlashcards([]);
  }, [selectedDeck?.id]);

  const handleTabClick = (tabId) => {
    if (tabId === "generate" && !selectedDeck) {
      setShowWarning(true);
      setTimeout(() => setShowWarning(false), 5000); // Hide warning after 5 seconds
    } else {
      setActiveTab(tabId);
      setShowWarning(false);
    }
  };

  return (
    <div className="w-full flex flex-col h-full">
      <div className="flex border-b border-zinc-700">
        {tabs.map((tab) => {
          const Icon = tab.icon;
          return (
            <button
              key={tab.id}
              onClick={() => handleTabClick(tab.id)}
              className={`px-4 py-2 font-medium text-sm focus:outline-none flex items-center ${
                activeTab === tab.id
                  ? "text-blue-500 border-b-2 border-blue-500"
                  : "text-zinc-400 hover:text-zinc-100"
              }`}
            >
              <Icon className="w-4 h-4 mr-2" />
              {tab.label}
            </button>
          );
        })}
      </div>
      {showWarning && (
        <CustomAlert message="Please create or select a deck before generating cards." />
      )}
      <div className="flex-1 overflow-hidden">
        {activeTab === "edit" && (
          <div className="h-full overflow-y-auto">
            {decks?.length > 0 ? (
              <div className="flex flex-col h-full">
                <div className="flex items-center justify-between p-2 bg-zinc-800">
                  <Toolbar
                    selectedDeck={selectedDeck}
                    onSave={onSave}
                    onFavorite={onFavorite}
                    onDeleteDeck={onDeleteDeck}
                    setIsEditing={setIsEditing}
                    setShowCreateDeckModal={setShowCreateDeckModal}
                    decks={decks}
                  />
                  <div className="relative w-64">
                    <input
                      type="text"
                      placeholder="Search cards..."
                      className="w-full pl-10 pr-4 py-2 text-sm rounded-lg bg-zinc-700 text-zinc-100 placeholder-zinc-400 focus:outline-none focus:ring-2 focus:ring-accent-indigo border border-zinc-600 transition-colors duration-200"
                      value={cardSearchTerm}
                      onChange={(e) => setCardSearchTerm(e.target.value)}
                    />
                    <Search className="absolute left-3 top-2.5 text-zinc-400 w-4 h-4" />
                  </div>
                </div>
                <div className="flex-1 overflow-y-auto mt-4">
                  <CardGrid
                    filteredCards={filteredCards}
                    setSelectedCard={setSelectedCard}
                    setIsCardDetailOpen={setIsCardDetailOpen}
                  />
                </div>
              </div>
            ) : (
              <NoDecksAvailable
                setShowCreateDeckModal={setShowCreateDeckModal}
              />
            )}
          </div>
        )}
        {activeTab === "generate" && (
          <div className="h-full overflow-y-auto">
            <FlashcardGenerator
              selectedDeck={selectedDeck}
              setSelectedDeck={setSelectedDeck}
              availableFlashcards={availableFlashcards}
              setAvailableFlashcards={setAvailableFlashcards}
              addedFlashcards={addedFlashcards}
              setAddedFlashcards={setAddedFlashcards}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default TabSystem;
