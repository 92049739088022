import React, { useState, useParams, useEffect } from "react";
import { useDecks } from "../../services/DeckProvider";
import { more_cards } from "../../services/apiCalls";
import { notifyError } from "../../components/Notification";
import Button from "../../components/Button";
import Tooltip from "../../components/Tooltip";
import { LoadingIndicator } from "../../components/LoadingAnimations";
import { FlashcardManager } from "./components/FlashcardManager";
import { IoSaveOutline } from "react-icons/io5";
import { v4 as uuidv4 } from "uuid";

const GenerateCards = ({ selectedDeck, setSelectedDeck }) => {
  const [availableFlashcards, setAvailableFlashcards] = useState([]);
  const [addedFlashcards, setAddedFlashcards] = useState([]);
  const [prompt, setPrompt] = useState("");
  const [showLoading, setShowLoading] = useState(false);
  const deckId = "22";
  const { user, fetchUserData, decks, access_token } = useDecks();
  const [thumbedDownCards, setThumbedDownCards] = useState([]);
  const { session } = useDecks();

  const handleAdd = (id) => {
    const flashcard = availableFlashcards.find(
      (flashcard) => flashcard.id === id
    );
    if (!flashcard) return;
    setAvailableFlashcards((current) =>
      current.filter((flashcard) => flashcard.id !== id)
    );
    setAddedFlashcards((current) => [...current, flashcard]);
  };

  const handleThumbsDown = (id) => {
    const flashcard = availableFlashcards.find(
      (flashcard) => flashcard.id === id
    );
    if (!flashcard) return;
    setThumbedDownCards((current) => [...current, flashcard]);
    setAvailableFlashcards((current) =>
      current.filter((flashcard) => flashcard.id !== id)
    );
  };

  const api_host = process.env.REACT_APP_API_HOST;

  //Function to update the deck
  const updateDeck = async (selectedDeck) => {
    const url = `${api_host}/deck/${selectedDeck["id"]}`;
    const header = {
      method: "PUT",
      body: JSON.stringify(selectedDeck),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access_token}`,
      },
      redirect: "follow",
    };

    try {
      const response = await fetch(url, header);
      const data = await response.json();
    } catch (error) {
      console.error("Error during fetch operation: ", error.message);
    }
  };

  const handleRemove = (id) => {
    const flashcard = addedFlashcards.find((flashcard) => flashcard.id === id);
    if (!flashcard) return;
    setAddedFlashcards((current) =>
      current.filter((flashcard) => flashcard.id !== id)
    );
    setAvailableFlashcards((current) => [...current, flashcard]);
  };

  const handleSave = async () => {
    //TODO: Update this condition so that also edited decks can be saved.
    //For now i commented this condition.
    //if (!selectedDeck || addedFlashcards.length === 0) return;
    if (!selectedDeck || addedFlashcards.length === 0) return;

    // Add addedFlashcards to the existing cards in the selected deck
    const newDeck = {
      ...selectedDeck,
      cards: [...selectedDeck.cards, ...addedFlashcards],
    };
    setSelectedDeck(newDeck);

    // Optionally clear addedFlashcards if needed
    setAddedFlashcards([]);

    //Update the deck in the database
    await updateDeck(newDeck);

    //Fetch the updated decks
    await fetchUserData();
  };

  //If a deck is selected, generate cards for that deck. Otherwise, create a new deck with the generated cards
  const onGenerate = async (currentDeck) => {
    if (!prompt) {
      notifyError("Please enter a prompt before generating cards");
      return;
    }
    setShowLoading(true);
    const url = `${api_host}/prompt`;
    const header = {
      method: "POST",
      body: JSON.stringify({ prompt }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access_token}`,
      },
      redirect: "follow",
    };
    try {
      const response = await fetch(url, header);
      const data = await response.json();
      const cards = JSON.parse(data.choices[0].message.content).cards;
      // Create a new deck with the generated cards only if a deck is not selected or not provided
      if (!currentDeck) {
        const newDeck = createNewDeck(prompt, cards);
        setAvailableFlashcards(cards);
        setShowLoading(false);
        return;
      }
      //const newDeck = createNewDeck(prompt, cards);
      //console.log("New Deck: ", newDeck);
      setAvailableFlashcards(cards);
      setShowLoading(false);
    } catch (error) {
      console.error("Error during fetch operation: ", error.message);
    }
  };

  const createNewDeck = async (deckName, cards) => {
    const url = `${api_host}/create-deck`;
    const payload = {
      id: uuidv4(),
      name: deckName,
      cards: cards,
      user_id: session.user.id,
      origin_owner_id: session.user.id,
    };

    const header = {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access_token}`,
      },
      redirect: "follow",
    };

    try {
      const response = await fetch(url, header);
      const data = await response.json();
    } catch (error) {
      console.error("Error during fetch operation: ", error.message);
    }
    return payload;
  };
  const onMoreCards = async () => {
    setShowLoading(true);
    const cards = more_cards(selectedDeck["cards"]);
    if (!prompt) {
      notifyError("Please enter a prompt before generating more cards");
      return;
    }
    const preprompt = " PLease dont give me one of the following cards: ";
    const new_prompt = prompt + preprompt + cards + thumbedDownCards;
    const url = `${api_host}/more-cards`;
    const header = {
      method: "POST",
      body: JSON.stringify({ prompt: new_prompt }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access_token}`,
      },
      redirect: "follow",
    };
    try {
      const response = await fetch(url, header);
      const data = await response.json();
      setAvailableFlashcards(JSON.parse(data.choices[0].message.content).cards);
      setShowLoading(false);
    } catch (error) {
      console.error("Error during fetch operation: ", error.message);
    }
  };

  //when there is no deck selected, create a new deck
  useEffect(() => {
    if (!selectedDeck) {
      const deck = decks.find((deck) => deck.id === deckId);

      if (!deck) {
        const emptyDeck = {
          id: deckId,
          name: "Untitled Deck",
          cards: [],
          user_id: session.user.id,
          origin_owner_id: session.user.id,
          tags: [],
          created_at: new Date().toISOString(),
        };
        setSelectedDeck(emptyDeck);
      } else {
        setSelectedDeck(deck);
      }
    }
  }, [deckId, decks, selectedDeck]);

  const NoItemsAvailable = () => {
    return (
      <div className="flex items-center justify-center h-fit bg-inherit rounded-lg">
        <div className="text-center p-6 bg-white my-2 shadow-md rounded-lg">
          <h2 className="text-2xl font-semibold text-gray-800 mb-4">
            No Cards Available
          </h2>
          <p className="text-gray-600">
            There are currently no cards to display. Please generate some.
          </p>
        </div>
      </div>
    );
  };

  return (
    <div className="max-w-full px-2 sm:px-6 lg:px-8 min-h-screen bg-zinc-900 flex flex-col">
      <div className="flex flex-col sm:flex-row justify-center items-center space-y-4 sm:space-y-0 sm:space-x-4 mb-4 sticky top-0 bg-zinc-900 z-10 py-4">
        <label
          htmlFor="prompt"
          className="relative block overflow-hidden rounded-md border bg-white border-gray-200 px-3 pt-3 shadow-sm focus-within:border-blue-600 focus-within:ring-1 focus-within:ring-blue-600 w-full sm:w-auto"
        >
          <input
            type="text"
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
            id="prompt"
            placeholder="Topic eg. basic math, advanced science, etc."
            className="peer h-8 w-full sm:w-80 text-black border-none bg-white p-0 placeholder-transparent focus:border-transparent focus:outline-none focus:ring-0 sm:text-sm"
          />
          <span className="absolute start-3 top-3 -translate-y-1/2 text-xs text-gray-700 transition-all peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-sm peer-focus:top-3 peer-focus:text-xs">
            Topic...
          </span>
        </label>

        <div className="flex flex-wrap justify-center items-center rounded-lg p-1 bg-zinc-200 space-x-1">
          <Tooltip tooltipText="Generate cards based on the prompt">
            <Button
              disabled={showLoading}
              h="h-8"
              onClick={() => onGenerate(selectedDeck)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 28 28"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-5 h-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9.813 15.904 9 18.75l-.813-2.846a4.5 4.5 0 0 0-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 0 0 3.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 0 0 3.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 0 0-3.09 3.09ZM18.259 8.715 18 9.75l-.259-1.035a3.375 3.375 0 0 0-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 0 0 2.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 0 0 2.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 0 0-2.456 2.456ZM16.894 20.567 16.5 21.75l-.394-1.183a2.25 2.25 0 0 0-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 0 0 1.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 0 0 1.423 1.423l1.183.394-1.183.394a2.25 2.25 0 0 0-1.423 1.423Z"
                />
              </svg>
            </Button>
          </Tooltip>
          <Tooltip tooltipText="Save the changes">
            <Button
              mx={0.5}
              h="h-8"
              disabled={
                availableFlashcards.length === 0 && addedFlashcards.length === 0
              }
              onClick={handleSave}
            >
              <IoSaveOutline size={18} />
            </Button>
          </Tooltip>
          <Tooltip tooltipText="Add 10 more cards">
            <Button
              disabled={
                (availableFlashcards.length === 0 &&
                  addedFlashcards.length === 0) ||
                showLoading
              }
              onClick={onMoreCards}
            >
              +10 cards
            </Button>
          </Tooltip>
          <Tooltip tooltipText="Save the changes and close the editor">
            <Button
              onClick={() => {
                handleSave();
                //setEditMode(false);
              }}
            >
              Save & close
            </Button>
          </Tooltip>
        </div>
      </div>

      <div className="flex-1 overflow-hidden">
        {showLoading ? (
          <LoadingIndicator />
        ) : (
          <div className="h-full">
            {availableFlashcards.length === 0 &&
            addedFlashcards.length === 0 ? (
              <NoItemsAvailable />
            ) : (
              <FlashcardManager
                availableFlashcards={availableFlashcards}
                addedFlashcards={addedFlashcards}
                handleAdd={handleAdd}
                handleRemove={handleRemove}
                handleThumbsDown={handleThumbsDown}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default GenerateCards;
