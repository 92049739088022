import React from "react";

const SimpleModal = ({
  isOpen,
  setIsOpen,
  children,
  closeButton = true,
  mode = "dark",
  className = "", // Add this line
}) => {
  if (!isOpen) return null;
  return (
    <div
      className={`fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 p-4 ${className}`} // Add className here
      onClick={() => setIsOpen(false)}
    >
      <div
        className={`${
          mode === "dark" ? "bg-background text-white" : "bg-white text-black"
        } rounded-lg shadow-lg p-4 sm:p-6 w-full max-w-5xl max-h-[90vh] overflow-y-auto`}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="overflow-y-auto max-h-[calc(90vh-8rem)]">
          <div className="prose prose-sm sm:prose-base dark:prose-invert max-w-none">
            {children}
          </div>
        </div>
        {closeButton && (
          <div className="mt-4 flex justify-end">
            <button
              className="bg-zinc-700 hover:bg-zinc-800 text-white font-bold py-2 px-4 rounded text-sm sm:text-base"
              onClick={() => setIsOpen(false)}
            >
              Close
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default SimpleModal;
