import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { supabase } from "../../supabaseClient";
import NavLinks from "./NavLinks";
import ProfileMenu from "./ProfileMenu";
import { useDecks } from "../../services/DeckProvider";
import MembershipBadge from "../MembershipBadge";
import { ChevronRight, LogOut, AlertTriangle } from "lucide-react";
import SimpleModal from "../SimpleModal";
import ReportIssue from "../ReportIssue";
import { useNavigate } from "react-router-dom";

function Navbar({ session, setMainContentPadding }) {
  const [isSidebarExpanded, setIsSidebarExpanded] = useState(false);
  const { membership } = useDecks();
  const location = useLocation();
  const [isReportModalOpen, setIsReportModalOpen] = useState(false);
  const API_HOST = process.env.REACT_APP_API_HOST;
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    user_name: session?.user?.user_metadata?.full_name || "",
    email: session?.user?.email || "",
    user_id: session?.user?.id || "",
    message: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitMessage, setSubmitMessage] = useState("");
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmitMessage("");
    try {
      const response = await fetch(`${API_HOST}/api/user-report`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user_name: formData.user_name,
          email: formData.email,
          user_id: formData.user_id,
          message: formData.message,
        }),
      });
      if (response.ok) {
        setSubmitMessage("Report sent successfully!");
        setFormData({ ...formData, message: "" });
      } else {
        const errorData = await response.json();
        setSubmitMessage(
          errorData.detail || "Error sending report. Please try again later."
        );
      }
    } catch (error) {
      console.error("Error:", error);
      setSubmitMessage("An error occurred. Please try again later.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleLogout = async () => {
    const { error } = await supabase.auth.signOut();
    if (error) console.log("Error logging out:", error.message);
  };

  const handleLogin = async () => {
    await supabase.auth.signIn({ provider: "google" });
  };

  const toggleSidebar = () => {
    setIsSidebarExpanded(!isSidebarExpanded);
  };

  useEffect(() => {
    if (session) {
      setMainContentPadding(
        isSidebarExpanded ? "md:pl-64 pb-16 md:pb-0" : "md:pl-20 pb-16 md:pb-0"
      );
    } else {
      setMainContentPadding("");
    }
  }, [session, isSidebarExpanded, setMainContentPadding]);

  return (
    <>
      <nav className="fixed top-0 left-0 right-0 z-50 bg-gradient-to-r from-gray-900 to-black text-white shadow-lg transition-all duration-300">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex h-16 md:h-20 items-center justify-between">
            <div className="flex-shrink-0">
              <Link to="/" className="flex items-center">
                <h4 className="text-transparent bg-clip-text bg-gradient-to-r from-indigo-400 to-purple-600 font-bold text-xl md:text-2xl tracking-wider">
                  Athena
                </h4>
              </Link>
            </div>

            {!session && (
              <div className="ml-10 hidden md:flex items-center space-x-6">
                <NavLinks session={session} currentPath={location.pathname} />
              </div>
            )}

            <div className="flex items-center">
              {!session && (
                <button
                  className="bg-gradient-to-r from-indigo-600 to-purple-600 text-white font-medium py-2 px-4 mx-1 md:px-6 rounded-full text-sm md:text-base transition-all duration-300 transform hover:scale-105 hover:shadow-lg"
                  onClick={() => navigate("/login")}
                >
                  Login
                </button>
              )}
            </div>
          </div>
        </div>
      </nav>

      {session && (
        <aside
          className={`hidden md:block fixed top-20 left-0 h-full bg-gradient-to-b from-gray-900 to-black text-white shadow-lg transition-all duration-300 ${
            isSidebarExpanded ? "w-64" : "w-20"
          }`}
        >
          <div className="p-5 overflow-hidden">
            <button
              onClick={toggleSidebar}
              className="w-fit text-left p-2 mb-4 text-gray-300 hover:bg-gray-800 rounded flex items-center justify-center md:justify-between"
            >
              <ChevronRight
                className={`transform transition-transform duration-300 ${
                  isSidebarExpanded ? "rotate-180" : ""
                }`}
              />
            </button>

            {isSidebarExpanded && (
              <MembershipBadge membership={membership} user={session?.user} />
            )}

            <NavLinks
              session={session}
              currentPath={location.pathname}
              isSidebar={true}
              isExpanded={isSidebarExpanded}
            />

            <button
              className={`flex items-center my-2 px-4 py-2 text-sm text-gray-300 hover:bg-indigo-600 hover:text-white rounded-md transition-colors duration-200 w-full text-left ${
                isSidebarExpanded ? "" : "justify-center"
              }`}
              onClick={() => setIsReportModalOpen(true)}
            >
              <AlertTriangle className="mr-3" size={18} />
              {isSidebarExpanded && "Report Issue"}
            </button>

            {isSidebarExpanded && (
              <button
                className="flex items-center my-2 px-4 py-2 text-sm text-gray-300 hover:bg-indigo-600 hover:text-white rounded-md transition-colors duration-200 w-full text-left"
                onClick={handleLogout}
              >
                <LogOut className="mr-3" size={18} /> Logout
              </button>
            )}
          </div>
        </aside>
      )}

      {session && (
        <nav className="md:hidden fixed bottom-0 left-0 right-0 z-50 bg-gradient-to-r from-gray-900 to-black bg-opacity-95 backdrop-blur-md text-white shadow-lg transition-all duration-300">
          <div className="flex items-center justify-around h-16 px-2">
            <NavLinks
              session={session}
              currentPath={location.pathname}
              isMobile={true}
            />
          </div>
        </nav>
      )}

      {/* Spacer */}
      <div className="h-16 md:h-20"></div>

      {/* Report Issue Modal */}
      <SimpleModal
        isOpen={isReportModalOpen}
        setIsOpen={setIsReportModalOpen}
        mode="dark"
      >
        <ReportIssue
          user={session?.user}
          API_HOST={API_HOST}
          onClose={() => setIsReportModalOpen(false)}
        />
      </SimpleModal>
    </>
  );
}

export default Navbar;
