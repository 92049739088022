import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDecks } from "../services/DeckProvider";
import { deleteDeck, useSaveDeck } from "../services/apiCalls";
import { findLastUsedDeck, handleDeckClick } from "../helpers/utils";

import CardEditor from "./Decks/CardEditor";
import CreateDeckModal from "../components/CreateDeckModal";
import { LoadingIndicator } from "../components/LoadingAnimations";
import { ErrorAlert } from "../components/Alert";
import GeneralModal from "../components/GeneralModal";
import Toolbar from "../components/Decks/Toolbar";
import CardDetailSlideOver from "../components/Decks/CardDetailSlideOver";
import DecksSidebar from "../components/Decks/DecksSidebar";
import EditableHeader from "../components/EditableHeader";
import TabSystem from "./Decks/TabSystem";

import { ChevronRight } from "lucide-react";

const DecksPage = () => {
  const [showCreateDeckModal, setShowCreateDeckModal] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [deleteDeckModal, setDeleteDeckModal] = useState(false);
  const [areDecksAvailable, setAreDecksAvailable] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  const [isEditing, setIsEditing] = useState({ state: false, mode: "" });
  const [isDecksSidebarCollapsed, setIsDecksSidebarCollapsed] = useState(false);
  const [deckSearchTerm, setDeckSearchTerm] = useState("");
  const [cardSearchTerm, setCardSearchTerm] = useState("");
  const [isMobile, setIsMobile] = useState(window.innerWidth < 640);

  const location = useLocation();
  const { id } = location.state || {};
  const { decks, user, session, isLoading, error, saveDeck } = useDecks();

  const [lastUsedDecks, setLastUsedDecks] = useState(() => {
    const storedDecks = localStorage.getItem("lastUsedDecks");
    return storedDecks ? JSON.parse(storedDecks) : [];
  });

  const [selectedDeck, setSelectedDeck] = useState(null);

  const [isPageLoading, setIsPageLoading] = useState(true);

  useEffect(() => {
    if (!isLoading && decks) {
      setAreDecksAvailable(decks.length > 0);
      setIsPageLoading(false);
    }
  }, [isLoading, decks]);
  useEffect(() => {
    if (decks && decks.length > 0) {
      setAreDecksAvailable(true);
    }
  }, [decks]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 640);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (decks.length > 0) {
      if (id) {
        const foundDeck = decks.find((deck) => deck.id === id);
        setSelectedDeck(foundDeck || decks[0]);
      } else if (lastUsedDecks.length > 0) {
        const foundDeck = findLastUsedDeck(decks, lastUsedDecks[0]);
        setSelectedDeck(foundDeck || decks[0]);
      } else {
        setSelectedDeck(decks[0]);
      }
    }
  }, [areDecksAvailable]);

  useEffect(() => {
    if (selectedDeck?.id && selectedDeck?.name) {
      handleDeckClick(selectedDeck.id, selectedDeck.name, setLastUsedDecks);
    }
  }, [selectedDeck]);

  const handleSaveDeck = async (deckToSave) => {
    try {
      const updatedDeck = await saveDeck(deckToSave);
      setSelectedDeck(updatedDeck);
      setShowSuccess(true);
    } catch (error) {
      console.error("Error saving deck:", error);
      setShowError(true);
    }
  };

  const onFavorite = async (deck) => {
    const newDeck = { ...deck, favorite: !deck.favorite };
    try {
      const updatedDeck = await saveDeck(newDeck);
      setSelectedDeck(updatedDeck);
      setShowSuccess(true);
    } catch (error) {
      console.error("Error updating deck:", error);
      setShowError(true);
    }
  };

  const deleteCard = async (cardId) => {
    const updatedCards = selectedDeck.cards.filter(
      (card) => card.id !== cardId
    );
    const updatedDeck = { ...selectedDeck, cards: updatedCards };

    try {
      const savedDeck = await saveDeck(updatedDeck);
      setSelectedDeck(savedDeck);
      setSelectedCard(null);
      setShowSuccess(true);
    } catch (error) {
      console.error("Error deleting card:", error);
      setShowError(true);
    }
  };
  const updateDeckName = async (name) => {
    const updatedDeck = { ...selectedDeck, name: name };
    try {
      const savedDeck = await saveDeck(updatedDeck);
      setSelectedDeck(savedDeck);
      setShowSuccess(true);
    } catch (error) {
      console.error("Error updating deck name:", error);
      setShowError(true);
    }
  };
  const filteredDecks =
    decks?.filter((deck) =>
      deck.name.toLowerCase().includes(deckSearchTerm.toLowerCase())
    ) || [];

  const filteredCards =
    selectedDeck?.cards.filter(
      (card) =>
        card.question.toLowerCase().includes(cardSearchTerm.toLowerCase()) ||
        card.answer.toLowerCase().includes(cardSearchTerm.toLowerCase())
    ) || [];

  const toggleDecksSidebar = () => {
    setIsDecksSidebarCollapsed(!isDecksSidebarCollapsed);
  };
  const [isCardDetailOpen, setIsCardDetailOpen] = useState(false);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <LoadingIndicator />
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center h-screen">
        <ErrorAlert
          title="Error fetching decks"
          message={error}
          setShowError={() => {}}
        />
      </div>
    );
  }

  return (
    <div className="flex h-[calc(100vh-140px)] bg-zinc-900 text-zinc-100">
      <DecksSidebar
        showCreateDeckModal={showCreateDeckModal}
        setShowCreateDeckModal={setShowCreateDeckModal}
        isDecksSidebarCollapsed={isDecksSidebarCollapsed}
        isMobile={isMobile}
        deckSearchTerm={deckSearchTerm}
        setDeckSearchTerm={setDeckSearchTerm}
        filteredDecks={filteredDecks}
        selectedDeck={selectedDeck}
        setSelectedDeck={setSelectedDeck}
        toggleDecksSidebar={toggleDecksSidebar}
      />

      <div className="flex flex-col flex-1 overflow-hidden border border-zinc-700 rounded-lg">
        <div className="flex items-center justify-between p-4 bg-zinc-800">
          {isMobile && isDecksSidebarCollapsed && (
            <button
              onClick={toggleDecksSidebar}
              className="p-2 mr-2 hover:bg-zinc-600 bg-zinc-700 border border-zinc-600 rounded-lg transition-colors duration-200"
            >
              <ChevronRight className="w-4 h-4 text-zinc-300" />
            </button>
          )}
          <div className="flex-1 mr-4">
            {selectedDeck && (
              <EditableHeader
                text={selectedDeck.name}
                updateDeckName={updateDeckName}
              />
            )}
          </div>
        </div>

        <div className="flex-1 overflow-hidden bg-zinc-800">
          <TabSystem
            decks={decks}
            cardSearchTerm={cardSearchTerm}
            setCardSearchTerm={setCardSearchTerm}
            filteredCards={filteredCards}
            setSelectedCard={setSelectedCard}
            setIsCardDetailOpen={setIsCardDetailOpen}
            setShowCreateDeckModal={setShowCreateDeckModal}
            selectedDeck={selectedDeck}
            setSelectedDeck={setSelectedDeck}
            onSave={handleSaveDeck}
            onFavorite={onFavorite}
            onDeleteDeck={() => setDeleteDeckModal(true)}
            setIsEditing={setIsEditing}
          />
        </div>
      </div>

      <CardDetailSlideOver
        isCardDetailOpen={isCardDetailOpen}
        setIsCardDetailOpen={setIsCardDetailOpen}
        selectedCard={selectedCard}
        setIsEditing={setIsEditing}
        deleteCard={deleteCard}
      />

      <GeneralModal
        isOpen={deleteDeckModal}
        setIsOpen={setDeleteDeckModal}
        onDelete={deleteDeck}
        selectedDeckId={selectedDeck?.id}
        decks={decks}
        setSelectedDeck={setSelectedDeck}
        setShowSuccess={setShowSuccess}
      >
        If you delete the deck, all the cards of this current deck will be lost.
      </GeneralModal>

      <CreateDeckModal
        isOpen={showCreateDeckModal}
        setIsOpen={setShowCreateDeckModal}
        setSelectedDeck={setSelectedDeck}
      />

      {isEditing.state && (
        <CardEditor
          setSelectedCard={setSelectedCard}
          selectedDeck={selectedDeck}
          setSelectedDeck={setSelectedDeck}
          selectedCard={selectedCard}
          setIsEditing={setIsEditing}
          onCardSelect={setSelectedCard}
          isEditing={isEditing}
        />
      )}
    </div>
  );
};

export default DecksPage;
